<template>
  <div class="bodycont">
    <comHeader  />
    <div class="contdiv">
      <div class="bgimg">
        <div class="topcont">
        <div class="tcont2">
           <el-carousel height="560px">
                <!-- <el-carousel-item   >
                <div class="swipercont" @click="toDetails1()">
                  <div class="simg">
                     <img src="../../assets/images/b1.png" />
                  </div>
                </div>
                </el-carousel-item> -->
                <el-carousel-item   >
                <div class="swipercont" @click="toDetails1()">
                  <div class="simg">
                     <div class="sbanner-sj">
                       <div class="sbleft-box">
                         <div class="stoptext">数字司法-行政执法监督云平台</div>
                         <div class="stopbtom"> 构建起“一站式、全链式、闭环式”执法监管格局， 推动执法工作由“治”向“智”转变 </div>
                       </div>
                       <div class="sbright-box">
                          <img src="../../assets/images/ch.png" />
                       </div>
                     </div>
                  </div>
                </div>
                </el-carousel-item>
                
                 <el-carousel-item  >
                <div class="swipercont" @click="toDetails1()">
                   <div class="simg">
                     <div class="sbanner-sj">
                       <div class="sbleft-box">
                         <div class="stoptext">数字公安— “数据赋能+科技创新”</div>
                         <div class="stopbtom">构建扁平化的指挥体系、立体化的防控体系、整体化的支撑体系,实现风险防控全面精准、决策指挥高效顺畅、警务数据融合共享、警种优势合成作战、网上网下同步应对、督察督办跟踪问效的现代化警务运行工作格局</div>
                       </div>
                       <div class="sbright-box">
                           <img src="../../assets/images/s44.png" />
                       </div>
                     </div>
                  </div>
                </div>
                </el-carousel-item>
                <el-carousel-item  >
                <div class="swipercont" @click="toDetails1()">
                   <div class="simg">
                     <div class="sbanner-sj">
                       <div class="sbleft-box">
                         <div class="stoptext">数字电网—数智化坚强电网 </div>
                         <div class="stopbtom">构建数智化坚强电网，打造新型电力系统建设的枢纽平台</div>
                       </div>
                       <div class="sbright-box">
                           <img src="../../assets/images/webs55.png" />
                       </div>
                     </div>
                  </div>
                </div>
                </el-carousel-item>
              </el-carousel>
        </div>

      </div>
      </div>
     
    </div>
    <!-- <cBar/> -->
    <cpDetail  @openChildFootDialog='openChildFootDialog' />
     <indexCase @openChildFootDialog='openChildFootDialog' />
     <!-- <bFa /> -->
  
    <bYs />
    <bNews />
    <indexFoot   />
      <bNav ref="bfoot" />
       <Dialog ref="dialog" />
  </div>

</template>

<script>
  // import api from '@/config/api'
  // import { } from '@/api/server'
  // import local from '../../utils/local'
  import cBar from '@/components/indexCpBar.vue'
  import comHeader from '@/components/header.vue'
   import cpDetail from '@/components/indexCp.vue'
  import bNav from '@/components/bottomNav.vue'
  import bFa from '@/components/indexFa.vue'
  import bYs from '@/components/indexYs.vue'
  import bNews from '@/components/indexNews.vue'
  import indexCase from '@/components/indexCase.vue'
  import indexFoot from '@/components/indexfoot.vue'
  import {
    swiper,
    swiperSlide
  } from "vue-awesome-swiper";
  import "swiper/css/swiper.min.css";
 import api from '@/config/api'
  import Dialog from '@/components/Dialog.vue'
  import {
    getRequst,postRequest
  } from '@/api/index'
  export default {
    components: {
      comHeader,
      bNav,
      swiper,
      swiperSlide,
      cBar,
      cpDetail,
      bFa,
      bYs,
      bNews,
      indexFoot,
      indexCase,
      Dialog
    },
    data() {
      return {
        swiperOptions: {
          autoplay: {
            delay: 2000,
            disableOnInteraction: true,
          },
          loop: true,
        },
        swiperOptions1: {
          slidesPerView: 3,
          spaceBetween: 30,
          freeMode: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          scrollbar: {
            el: '.swiper-scrollbar',
          },
        },

        slideList: [{url:'1.png',id:1},{url:'2.png',id:2},{url:'3.jpg',id:3},{url:'4.png',id:4} ],
        ishow: 1, //新闻1,通知公告2
        datalist: [],
        datalist1: [],
        cindex: 1,
        cindex1: 3,
        cindex2: 5,
        cindex3: 7,
        cindex4: 9,
        cindex5: 11,
        isactive: 1,
        isnews:1,
        newList:[],
        dataInfo:{},//统计数量
        base_url:api.base_url,
        friendList:[],
        allData:[],
        nodata:require('../../assets/images/infonodata.png'),
        searchValue:'',
        page:{
          page:1,
          limit:5
        },

      }
    },
    created() {
      // this.getNewsList(1)
      // this.getData()
      // this.getAchieve()
      //this.getBannerList()
      // this.getfriendPageList()
    },


    methods: {
    openChildDialog(){
      this.$refs.dialog.openDialog()
    },
    openChildFootDialog(){
        this.$refs.dialog.openDialog()
    },
      getData(){
        postRequest(api.getStatisticsData, {}).then(res => {
          this.dataInfo = res.map
        })
      },
      getBannerList(){
        postRequest(api.getBanner, {}).then(res => {
          this.slideList= res.carouselChartLists
          this.slideList.forEach(item=>{
            item.filesList.forEach(items=>{
              if(items.msrIsMaster==1){
                item.imgUrl = items.msrUrlStatic
              }else{
                 item.imgUrl = item.filesList[0].msrUrlStatic
              }
            })
          })
        })
      },
      getAchieve(){
        let data ={
          page:1,
          limit:5,
        }
        postRequest(api.getBanner, data).then(res => {
         this.allData= res.carouselChartLists
           this.allData.forEach(item=>{
             item.filesList.forEach(items=>{
               if(items.msrIsMaster==1){
                 item.imgUrl = items.msrUrlStatic
               }else{
                  item.imgUrl = item.filesList[0].msrUrlStatic
               }
             })
           })
         })
      },

      getserviceList(type) {
         let data = {
          page:1,
          limit: 5,
         }
        postRequest(api.getservice, data).then(res => {
          if(res.code==0){
            res.data.forEach(item=>{
              item.imgUrl = item.file
            })
             this.allData = res.data

          }
        })
      },
      getproductList(type) {
         let data = {
          page:1,
          limit: 5,
         }
        postRequest(api.getproduct, data).then(res => {
          if(res.code==0){
            res.data.forEach(item=>{
              item.imgUrl = item.file
            })
             this.allData = res.data

          }
        })
      },



      //获取友情链接
      getfriendPageList(){
        let data ={
          page:1,
          limit:100,
          approvalStatus:4
        }
        postRequest(api.getFPageList, data).then(res => {
          res.page.list.forEach(item=>{
            if(item.filesList){
              item.imgUrl = item.filesList[0].msrUrlStatic
            }else{
              item.imgUrl = ''
            }
          })
             this.friendList = res.page.list
        })
      },
      tolink(item){
        window.open(item.linksAddress)
      },
      searchBtn(){
        this.page.page = 1
        this.page.limit = 20
        this.getNewsList(this.isnews)

      },
      clear(){
        this.page.page = 1
        this.page.limit = 20
        this.searchValue = ''
        this.getNewsList(this.isnews)
      },

      //获取列表
      getNewsList(type) {
        let data = {
          page:this.page.page,
          limit:this.page.limit,
          checkstate:3,
        }
        data.title=this.searchValue
        if(type==1){
          data.newstype=1
        }else if(type==3){
          data.newstype=2
        }else{
           data.newstype=3
        }
        postRequest(api.newsPolicy, data).then(res => {
          if(res.page.list){
            res.page.list.forEach(item=>{
              item.year = item.submittime.substring(0,4)
              item.month = item.submittime.substring(5,7)
              item.day = item.submittime.substring(8,10)
            })
          }
           this.newList = res.page.list
        })

      },
      toDetails1(data){
       if(data.amType==0){
         this.$router.push({
           path: '/resultDetails',
           query: {
             id: data.aid,
             url: 'resultsinfo',
             name: '科技成果',
             amType:data.amType,
           }
         });
       }else{
         this.$router.push({
           path: '/resultDetails',
           query: {
             id: data.amDetailId,
             url: 'resultsinfo',
             name: '科技成果',
             amType:data.amType,
           }
         });
       }
      },
      //成果详情
      toDetails(data) {
        if(this.isactive==1){
          if(data.amType==0){
            this.$router.push({
              path: '/resultDetails',
              query: {
                id: data.aid,
                url: 'resultsinfo',
                name: '科技成果',
                amType:data.amType,
              }
            });
          }else{
            this.$router.push({
              path: '/resultDetails',
              query: {
                id: data.amDetailId,
                url: 'resultsinfo',
                name: '科技成果',
                amType:data.amType,
              }
            });
          }

        }

        if(this.isactive==2){

         this.$router.push({
           path: '/resultDetails',
           query: {
             id: data.aId,
             url: 'parkinfo',
             name: '园企产品',
             type:'park'
           }
         });
        }
        if(this.isactive==3){
         this.$router.push({
           path: '/resultDetails',
           query: {
             id: data.aId,
             url: 'serviceinfo',
             name: '科技服务',
             type:'service'
           }
         });
        }
      },
      //新闻详情
      tonewsDetails(id){
        if(this.isnews==1){
          this.$router.push({
            path:'/newsdetails',
            query:{
              id:id,
              url:'notice',
              name:'通知公告'
            }
          })
        }else{
          this.$router.push({
            path:'/newsdetails',
            query:{
              id:id,
              name: '政策新闻',
              url: 'aboutus',
            }
          })
        }
      },

      //公告新闻切换
      changeNews(type) {
        this.isnews = type
        this.getNewsList(type)
      },
      //查看更多新闻
      tomore() {
        if (this.ishow == 1) {
          this.$router.push({
            path: '/notice',
          })
        } else {
          this.$router.push({
            path: '/conversion',
          })
        }
      },
      //查看详情
      //详情页面
      tourl(item) {
        let data = {}
        if (this.ishow == 1) {
          data = {
            id: item.id,
            url: 'notice',
            name: '通知公告'
          }
        } else {
          data = {
            id: item.id,
            url: 'conversion',
            name: '成果转化政策'
          }
        }
        this.$router.push({
          path: '/newsdetails',
          query: data,
        })
      },
      toUrlList(data){
        this.$router.push({
          path: data,
        })
      },

      //查看更多
      getmore(index) {
        if (index == 1) {
          this.$router.push({
            path: '/resultsinfo'
          })
        } else if (index == 2) {
          this.$router.push({
            path: '/parkinfo'
          })
        } else if (index == 3) {
          this.$router.push({
            path: '/serviceinfo'
          })
        } else if (index == 4) {
          this.$router.push({
            path: '/prelease'
          })
        }
      },

      //切换背景
      changebg(data) {
        if (data == 1 || data == 2) {
          this.cindex = data
        } else if (data == 3 || data == 4) {
          this.cindex1 = data
        } else if (data == 5 || data == 6) {
          this.cindex2 = data
        } else if (data == 7 || data == 8) {
          this.cindex3 = data
        } else if (data == 9 || data == 10) {
          this.cindex4 = data
        } else if (data == 11 || data == 12) {
          this.cindex5 = data
        }

      },

      //成果登记
      tologin(type) {
          if(!sessionStorage.getItem('isLogin')||sessionStorage.getItem('isLogin')==0){

              window.location.href = api.link_url+'/login.html'
          }else if(sessionStorage.getItem('isLogin')==1){
               window.location.href = api.link_url+'/manager.html?gettype='+type
             // window.location.href = api.link_url + '/manager.html'
          }
      },

      //切换选项
      changeTitle(data) {
        this.isactive = data;
        if(data==1){
         this.getAchieve()
        }
        if(data==2){
           this.getproductList(data)
        }
        if(data==3){
           this.getserviceList(data)
        }
        // else if(data==4){
        //   this.allData= []
        //   this.$message.info('暂无数据!')
        //   this.isactive = 1
        // }
      }


    }
  }
</script>

<style lang="less" scoped>
 .search{
    display: flex;
     justify-content: flex-start; ;
    margin-bottom: 20px;
        width: 1000px;
        margin: 0 auto;
    .demo-input-suffix{
      margin-right: 20px;
    }
  }
  .tcont1 .info {
    /deep/ span {
      line-height: 20px;
    }
  }
  .bodycont {
    width: 100%;
  }

  .contdiv {
    width: 100%;
    margin: 0px auto;
    // padding: 30px 0 0px;
    font-size: 12px;
    background: url('../../assets/images/bj.jpg') no-repeat center -40px #fff;
    //  background: url('../../assets/images/bg1.png') no-repeat center -40px #fff;
  }

  .content {
    width: 80%;
    margin: 20px auto;
    font-size: 12px;


  }
  .bgimg{
    width: 100%;

  }
  .topcont {
    display: flex;
    justify-content: space-between;
    width:100%;
    margin:0 auto;



    .tcont2 {
      width: 100%;

      .swipercont {
        width: 100%;
        // margin: 20px auto;
        display: flex;
        flex-wrap: nowrap;

        .simg {
          width: 100%;
          height: 560px;
          overflow: hidden;

          img {
            height: 100%;
            width: auto;
            min-width: 100%;
          }
        }

        .tcont1 {
          width: 30%;
          height: 380px;
          border: 1px solid #ddd;
          overflow: hidden;
          position: relative;
          background: #fff;
          border-radius: 5px;
          text-align: center;
          h2{
            padding: 20px;
            text-align: center;
            font-size: 20px;
          }
          .info{
            font-size: 16px;
            text-align: left;
            padding: 0 10px;
          }

        }

      }
    }

    .tcont3 {
      width: 24%;
      height: 280px;
      border: 1px solid #000;
    }
  }

  .uils_bg {
    background-color: #fff;
    padding: 0 10px;
    overflow: hidden;

    .lists {
      line-height: 33px;
      width: 100%;
      display: inline-block;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      margin-top: 10px;

      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        display: inline-block;
        height: 30px;
        line-height: 30px;

        i {
          display: inline-block;
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          border-radius: 50%;
          background-color: #00A0E9;
          color: #fff;
          margin-right: 6px;
        }
      }

      span {
        font-size: 13px;
        color: #000;
      }
    }
  }

  .tnav {
    display: flex;
    justify-content: space-between;
    // background-color: #00428E;
    // color: #fff;
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    font-size: 16px;
    border-top: 6px solid #00428E;
    color: #00428E;
    border-bottom: 1px solid #ddd;

    .tnavs {
      display: flex;
      justify-content: flex-start;
    }

    span {
      cursor: pointer;
      color: #00428E;

    }
  }

  .countdiv {
    width:1190px;
    margin: 20px auto;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-size: 16px;
    padding: 20px 0;
    box-shadow: 0 0 8px #ddd;

    .cborder {
      border-right: 1px solid #000;
    }

    .count {
      flex: 1;

      .num {
        margin-top: 10px;
      }

      .num span {
        font-size: 30px;
        color: #00428E;
        line-height: 30px;
        font-family: "book antiqua";
        margin: 0 10px 0 0;
      }
    }
  }


  .indexThree {
    width: 100%;
    padding: 0px 0px 0px;
    transition: 0.5s;
    position: relative;
    background: #fff;

    .cont1 {
      min-height: 300px;

      .site_e {
        display: flex;
        justify-content: space-around;
        // width: 1190px;
        margin:0px auto;
        flex-wrap: wrap;
        align-items: center;
        .box {
          // width: 250px;
          text-align: center;
          margin: 20px 0 0;
          border: 1px solid #00428E;
          padding: 20px;
          // box-shadow: 0 15px 35px rgb(0 0 0 / 10%);
          border-radius: 0 20px 20px 20px;
          overflow: hidden;
          box-shadow: 0 0px 5px #ddd;

          .icon_img {
            img {
              width: 50px;
              height: 50px;
            }
          }

          .title {
            font-size: 16px;
            color: #000;
            margin-top: 10px;
          }
        }

        .box:hover {
          background-color: #00428E;

          .title {
            color: #fff;
          }
        }
      }
    }
  }

  .cont1 {
    min-height: 300px;

    .titles_ {
      margin: 18px 0 40px;
      text-align: center;
      font-size: 16px;
      letter-spacing: 10px;
      font-weight: bold;
    }

    .site_h2 {
      height: 60px;
      width: 350px;
      margin: 0 auto;
      line-height: 60px;
      text-align: center;


      span {
        font-size: 30px;
        line-height: 60px;
        color: #00A0E9;
        width: 100%;
      }
    }

    .em {
      width: 1180px;
      font-style: normal;
      color: #888;
      height: 2px;
      background: #eee;
      margin: 20px auto;

      .idiv {
        width: 160px;
        background: #00A0E9;
        height: 6px;
        margin: -3px auto 0;
        border-radius: 5px;
      }
    }
  }

  .indexThr {
    width: 100%;
    padding: 30px 0px 40px;
    transition: 0.5s;
    position: relative;
    background: #fafafa;
    background: url('../../assets/images/chengguo.png') no-repeat center -40px #fff;

    .site_e {

      width: 80%;
      margin: 20px auto;
      display: flex;

      .sitetitle {
        display: flex;
        flex-wrap: wrap;
        // background-image: linear-gradient(to bottom, #156bff 0, #05b7f7 100%);
        height: 50px;
        color: #fff;
        position: relative;
        background: #0078ce;
        width: 185px;
        height: 296px;
        padding: 30px 0 0 25px;


        span {

          display: block;
          cursor: pointer;
          height: 50px;
          line-height: 50px;
          color: #fff;
          font-size: 15px;
          text-indent: 24px;
          width: 100%;


        }
      }

      .active {
        background: #fff;

        color: #000 !important;
      }

      .site-c:last-child {
        border: none;
      }

      .sitec {
        display: flex;
        justify-content: flex-start;
      }

      .sitemore {
        position: absolute;
        right: 20px;
        top: 10px;
        height: 24px;
        line-height: 24px;
        padding: 0 10px;
        border: 1px #0078ce solid;
        border-radius: 12px;
        font-size: 14px;
        color: #0078ce;
        cursor: pointer;
      }

      .site-c {
        // display: flex;
        // justify-content: flex-start;
        background-color: #fff;
        padding: 44px 0px 30px;
        width: 980px;
        position: relative;

        .li {
          width: 25%;
          padding: 0 20px;
          border-right: 1px solid #eee;

          img {
            width: 100%;
            height: 140px;
          }
        }

        p {
          height: 30px;
          line-height: 30px;
          overflow: hidden;
          padding-left: 10px;
          font-size: 14px;
          margin: 6px 0;
          cursor: pointer;
          position: relative;

          &:after {
            content: ' ';
            position: absolute;
            left: 0px;
            top: 12px;
            width: 4px;
            height: 4px;
            background-color: #156bff;
            border-radius: 50%;
          }
        }

      }

    }
  }

  .friendLink_07 {
    width: 100%;
    padding: 30px 0px 40px;
    transition: 0.5s;
    position: relative;
    background: #fff;
    background: url('../../assets/images/incase-bg.png') no-repeat center -40px #fff;

    .site_about{
      display: flex;
      width: 1000px;
      margin: 20px auto;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;

      .box {
        box-sizing: border-box;
         width: 200px;
         text-align: center;
         padding: 10px;
         img{
           width: 100%;
           height: 100%;
           height: 50px;
         }

      }
    }

  }



  .inpro-area {
    padding-bottom: 20px;
    position: relative;
    padding-top: 20px;
    background: url(../../assets/images/inpro-bg.jpeg) no-repeat left center;
    background-size: auto 100%;
  }

  .commonweb1400 {
    margin: 0px auto;
    // max-width: 1400px;
    padding: 0 60px;
  }

  .public-title-5 h4 {
    font-size: 42px;
  }

  .public-title-5 span {
    position: relative;
    padding: 5px;
    display: inline-block;
    z-index: 3;
  }

  .public-title-5 h4::after {
    width: 30px;
    height: 30px;
    background-size: 30px auto;
    -webkit-transform: translate(-16px, -8px);
    -ms-transform: translate(-16px, -8px);
    -o-transform: translate(-16px, -8px);
  }

  .public-title-5 h4::after {
    content: '';
    position: absolute;
    -webkit-transform: translate(-13px, -2px);
    -ms-transform: translate(-13px, -2px);
    -o-transform: translate(-13px, -2px);
    transform: translate(-13px, -2px);
    background: url(../../assets/images/ptitle5.png) no-repeat center center;
  }

  .public-title-5 span::before {
    width: 20px;
    height: 20px;
    bottom: -3px;
    left: -20px;
  }

  .public-title-5 span::before {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-box-shadow: 0px 3px 7px 0px rgb(57 136 234 / 35%);
    box-shadow: 0px 3px 7px 0px rgb(57 136 234 / 35%);
    background-image: -webkit-linear-gradient(162deg, #00e9ff 0%, #00c2f7 29%, #00a2ff 49%, #4166ff 75%, #3401dc 100%);
    background-image: -o-linear-gradient(162deg, #00e9ff 0%, #00c2f7 29%, #00a2ff 49%, #4166ff 75%, #3401dc 100%);
    background-image: linear-gradient(-72deg, #00e9ff 0%, #00c2f7 29%, #00a2ff 49%, #4166ff 75%, #3401dc 100%);
    background-blend-mode: normal;
  }

  .wow {
    text-align: center;
  }

  .incase-tab {
    font-size: 0;
    text-align: center;
    cursor: pointer;
    width: 50%;
    margin: 0 auto;
        display: flex;
        white-space: nowrap;
        justify-content: center;
  }

  .incase-tab li {
    font-size: 16px;
    vertical-align: top;
    display: inline-block;
    color: #333333;
    line-height: 1;
    position: relative;
    border-bottom: solid 1px #d2e5ff;
    width: 20%;
    padding-bottom: 10px;
  }

  .incase-tab li.cur {
    color: #00428E;
    cursor: pointer;
  }

  .incase-tab li.cur::after {
    width: 100%;
  }

  .incase-tab li::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 3px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    background-image: -webkit-linear-gradient(162deg, #00e9ff 0%, #00c2f7 29%, #00a2ff 49%, #4166ff 75%, #3401dc 100%);
    background-image: -o-linear-gradient(162deg, #00e9ff 0%, #00c2f7 29%, #00a2ff 49%, #4166ff 75%, #3401dc 100%);
    background-image: linear-gradient(-72deg, #00e9ff 0%, #00c2f7 29%, #00a2ff 49%, #4166ff 75%, #3401dc 100%);
    background-blend-mode: normal;
  }

  .indesc {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .site-c:last-child {
    border: none;
  }

  .sitec {
    display: flex;
    // justify-content: flex-start;
    overflow-x: auto;
  }

  .sitemore {
    position: absolute;
    right: 20px;
    top: 10px;
    height: 24px;
    line-height: 24px;
    padding: 0 10px;
    border: 1px #0078ce solid;
    border-radius: 12px;
    font-size: 14px;
    color: #0078ce;
    cursor: pointer;
  }

  .site-c {
    // display: flex;
    // justify-content: flex-start;
    background-color: #fff;
    padding: 44px 0px 30px;
    width: 1180px;
    position: relative;

    .li {
      width: 25%;
      padding: 0 20px;
      border-right: 1px solid #eee;

      img {
        width: 100%;
        height: 140px;
      }
    }

    p {
      height: 30px;
      line-height: 30px;
      overflow: hidden;
      padding-left: 10px;
      font-size: 14px;
      margin: 6px 0;
      cursor: pointer;
      position: relative;

      &:after {
        content: ' ';
        position: absolute;
        left: 0px;
        top: 12px;
        width: 4px;
        height: 4px;
        background-color: #156bff;
        border-radius: 50%;
      }
    }

  }

  .incasecont {
    margin: 30px auto 0;
    text-align: center;
    width:1000px;
    .incaseimg{
      position: relative;
    }
    .incaseinfo {
         margin-top: 10px;
         width: 100%;
         display: block;
         color: inherit;
         font-size: inherit;
         white-space: nowrap;
         text-overflow: ellipsis;
         overflow: hidden;
         color:#00A0E9;
          position: absolute;
          z-index: 2;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%,-50%);
          font-size: 16px;
    }

    // .swiper-container{
    //   padding: 20px!important;
    //       overflow-x: auto!important;
    // }
  }

  .site_e {
    display: flex;
    justify-content: space-around;
    width: 1000px;
    margin: 20px auto;
    flex-wrap: wrap;
    align-items: center;

    .box {
      width: 240px;
      text-align: center;
      margin: 20px 0 0;
      border: 1px solid #00428E;
      padding: 20px;
      // box-shadow: 0 15px 35px rgb(0 0 0 / 10%);
      // border-radius: 0 20px 20px 20px;
      overflow: hidden;
      box-shadow: 0 0px 5px #ddd;
      border-radius: 10px;

      .icon_img {
        img {
          width: 50px;
          height: 50px;
        }
      }

      .title {
        font-size: 16px;
        color: #000;
        margin-top: 10px;
      }
    }

    .box:hover {
      background-color: #00428E;

      .title {
        color: #fff;
      }
    }
  }

  .h-add-btn {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 30px;
  }

  .public-more2 a {
    width: 155px;
    height: 44px;
    padding-left: 40px;
    line-height: 44px;
    font-size: 16px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
  }

  .public-more2 a {
    display: inline-block;
    padding: 0 15px;
    background-image: url(../../assets/images/h-index-more-bg.png);
    background-size: 100% 100%;
    background-position: left top;
    color: #00428E;
    position: relative;
  }

  .public-more2 a::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 130px;
    height: 100%;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
  }

  .public-more2 a::after {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translate(5px, -50%) rotate(-45deg);
    -ms-transform: translate(5px, -50%) rotate(-45deg);
    -o-transform: translate(5px, -50%) rotate(-45deg);
    transform: translate(5px, -50%) rotate(-45deg);
    width: 4px;
    height: 4px;
    border-right: solid 1px #00428E;
    border-bottom: solid 1px #00428E;
  }

  .public-more2 a:hover {
    color: #fff;
    background-image: url(../../assets/images/h-index-more-bg-o.png);
    width: 165px;
  }

  .public-more2 a:hover::after {
    border-color: #fff;
  }

  .public-more2 a:hover::before {
    -webkit-box-shadow: 0px 3px 7px 0px rgba(57, 136, 234, 0.35);
    box-shadow: 0px 3px 7px 0px rgba(57, 136, 234, 0.35);
  }




  .innews {
    padding-top: 25px;
    // padding-bottom: 20px;
    background: url(../../assets/images/innews-bg.jpeg) no-repeat;
  }

  .innews-tab {
    font-size: 0;
    text-align: center;
    margin-top: 15px;
    padding-bottom: 2px;
  }

  .innews-tab li {
    font-size: 14px;
    vertical-align: top;
    display: inline-block;
    color: #333333;
    padding: 0 10px 8px;
    line-height: 1;
    position: relative;
    border-bottom: solid 1px #d2e5ff;
    cursor: pointer;
  }

  .innews-tab li::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 3px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    background-image: -webkit-linear-gradient(162deg, #00e9ff 0%, #00c2f7 29%, #00a2ff 49%, #4166ff 75%, #3401dc 100%);
    background-image: -o-linear-gradient(162deg, #00e9ff 0%, #00c2f7 29%, #00a2ff 49%, #4166ff 75%, #3401dc 100%);
    background-image: linear-gradient(-72deg, #00e9ff 0%, #00c2f7 29%, #00a2ff 49%, #4166ff 75%, #3401dc 100%);
    background-blend-mode: normal;
  }

  .innews-tab li.cur {
    color: #00428E;
  }

  .innews-tab li.cur::after {
    width: 100%;
  }

  .innews-views {
    margin-top: 20px;
  }

  .innews-views .innews-l img {
    width: 100%;
  }

  .innews-l {
    width: 48%;
    float: left;
  }

  .innews-l .pic {
    height: 374px;
    position: relative;
    overflow: hidden;
  }

  .pic img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
  }

  .word {
    padding: 34px 32px 35px 32px;
    box-shadow: none;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    background: #fff;
    box-sizing: border-box;
  }

  .innews-l h2 {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .innews-views .innews-l h2 {
    line-height: 1;
    color: #080808;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .innews-l .desc {
    color: #666666;
    line-height: 28px;
    max-height: 56px;
    height: 56px;
    display: -webkit-box;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .innews-r {
    width:1000px;
    margin: 0 auto;
  }

  .innews-r li {
    // padding-bottom: 14px;
    // margin-bottom: 14px;
    border-bottom: solid 1px #E6EDFA;
    // padding: 12px 0;
    // margin-left: 15%;
  }

  .innews-info-view {
    // padding: 16px 60px 16px 16px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    -webkit-border-radius: 2px;
    border-radius: 2px;
  }
  .innewsinfo{
    display: flex;
    justify-content: flex-start;
    margin: 10px 0;
  }

  .innews-info-view .box {
    // display: table;
    // table-layout: fixed;
    // width: 100%;
  }

  .innews-info-view .date {
    width: 70px;
    height: 70px;
     padding-top: 15px;

  }

  .date {
    display: table-cell;
    vertical-align: middle;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    position: relative;
    background: #ECECED;
    color: #666666;
  }


  .date .d {
    font-size: 28px;
    margin-bottom: 6px;
  }

  .date .y_m {
    line-height: 1;
    font-size: 12px;
    text-align: center;
    letter-spacing: -1px;
  }



  .innews-info {
    padding-left: 30px;
    padding-top: 10px;
  }

  .innews-info-view .innews-info {
    padding-left: 30px;
    // display: table-cell;
    vertical-align: middle;
    width: 900px;
    text-align: left;
  }
  .innews-info h2{
    overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
  }
  .innews-info h2 a {
    width: 100%;
    display: block;
    color: inherit;
    font-size: inherit;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .innews-info p {
    line-height: 1;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 10px;
  }

  .innews {
    // padding-bottom: 60px;
    position: relative;
  }

  .innews::before {
    content: '';
    position: absolute;
    top:16%;
    left:12%;
    width: 90px;
    height: 400px;
    background: url(../../assets/images/innews-bg.png) no-repeat;
    background-size: cover;
  }

  .swiper-slide{
    .img{
      width: 100%;
      height: 300px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .mdiv{
    text-align: left;
    font-size: 16px;
    padding:15px 20px;
  }
  .company{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }
  .simg{
    width:1200px!important;
    margin:0 auto;
  }
  .sbanner-sj{
    display: flex;
    height: 540px;
    align-items: center;
    justify-content: space-between;
  }
  .stoptext{
    font-family: "WHDICFONT" !important;
    color:#ffffff;
    font-size:36px;
  }
  .stopbtom{
    font-size: 18px;
    color: rgba(255,255,255,.8);
    margin-top: 50px;
    font-weight: lighter;
    width: 450px;
    line-height: 38px;
    text-align: center;
  }
  .sbright-box{
    width:570px;
    overflow: hidden;
  }
  .sbright-box img{
    width:100%!important;
    height:auto!important;
  }
</style>
