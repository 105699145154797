<template>
  <div class="xa_cpb">
      <div class="dic_title">最新动态</div>
       <div class="dic_inner ">
         <div class="zs_box dic_flex dic_jsa">
           <div class="newsitem">
             <a href="/notice/1">
              <div class="newsimg">
                  <img src="../assets/images/news/news61.png" alt="">
              </div>
               <div class="newsrit">
                 <div class="nestitle">
                   <p>递进式执法 实现现场执法简易化 </p>
                     <i class="dates">2023-12-08</i>
                   </div>
                 <div class="nestexcont"></div> 
               </div>
               </a>
           </div>
            <div class="newsitem">
               <a href="/notice/2">
              <div class="newsimg">
                  <img src="../assets/images/news/news2.png" alt="">
              </div>
               <div class="newsrit">
                <div class="nestitle">
                   <p>全数据汇聚、全要素管控、全流程闭环 </p>
                     <i class="dates">2024-01-13</i>
                   </div>
                 <div class="nestexcont"></div> 
               </div>
                </a>
           </div>
            <div class="newsitem">
                <a href="/notice/3">
              <div class="newsimg">
                  <img src="../assets/images/news/news31.png" alt="">
              </div>
               <div class="newsrit">
                <div class="nestitle">
                   <p>新春开工！新征程，再出发 </p>
                     <i class="dates">2024-02-18</i>
                   </div>
                 <div class="nestexcont"></div> 
               </div>
                </a>
           </div>
         </div>
       </div>
  </div>
</template>

<script>
  import api from '@/config/api'
  import {
    getRequst,postRequest
  } from '@/api/index'
export default {

  data() {
    return {
       dataInfo:{},
     }
  },
  created() {

  },
  mounted() {
    //this.getInfo()
  },
  methods: {
    //获取列表
    getInfo() {
      let data = {
        asId: 1,
      }
      postRequest(api.aboutUsId, data).then(res => {
         this.dataInfo = res.data
      })

    },
  },

}
</script>

<style lang="less" scoped>
  .xa_cpb{
    width:100%;
    background: #ffffff;
    padding:30px 0 80px;
  }
  .c_card_inner{
    padding:40px 0;
  }
  .c_card{
    width: 35%;
    height: 400px;
    margin-right: 40px;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px 0px rgba(0, 117, 255, 0.12);
    }
    .c_card_tc{
      text-align: center;
      font-size: 22px;
      height:60px;
      border-bottom:1px #F2F3F5 solid;
      line-height: 60px;
      margin-bottom:30px;
    }
    .c_card_list{
      padding:5px 20px;
      line-height: 24px;
    }
    .zs_items{
      width:28%;
      overflow:hidden;
      border:1px #F2F3F5 solid;
      padding-bottom:40px;
      position: relative;
      border-radius:10px 10px 0 0;
    }
    .zs_box{
      margin-top:20px;
    }
    .zs_pics{
      width:100%;
      height:140px;
      background: #e5e5e5;
      border-radius:6px 6px 0 0;
      margin-bottom:40px;
       filter: brightness(50%);
    }
    .news_text{
      position: absolute;
      left:30px;
      top:80px;
      color:#ffffff;
      z-index:2;
      font-size: 18px;
      font-weight: bold;
    }
    .zs_test{
      width:100%;
      font-size: 14px;
      padding:0 20px;
      line-height: 18px;
      margin-top:20px;
    }
    .zs_test span{
      display: inline-block;
      color:#999;
      margin-right:15px;
    }
    .zs_pics01{
      background: url('../assets/images/index/p1.jpg') no-repeat center center;
    }
    .zs_pics02{
      background: url('../assets/images/index/p3.jpg') no-repeat center center;
    }
    .zs_pics03{
      background: url('../assets/images/index/p4.jpg') no-repeat center center;
    }
    .newsitem{
      width:30%;
    }
    .newsimg{
      height:200px;
      overflow: hidden;
      border:1px #999 solid;
      border-radius: 3px;
    }
    .newsimg img{
      width:100%;
      height:100%;
    }
    .nestitle{
     margin-top:20px;
      display: flex;
      justify-content: space-between;
      padding:0 10px;
    }
    .dates{
      font-size: 14px;
      color:#999;
    }
</style>
