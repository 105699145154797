<template>
  <div class="xa_cpb">
     <div class="dic_title">核心业务</div>
     <div class="dic_sub_title ">深耕三大领域,打造一站式数字化服务</div>
     <div class="dic_inner">

       <div class="cpboxs  dic_flex mt40">
       
        <div class="cpcard">
             <div class="cpcard_title">
                <i><svg t="1709688700151" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1092" width="30" height="30"><path d="M258.438554 860.16c80.2816 44.48256 159.62112 66.7648 237.9776 66.7648 78.39744 0 165.23264-22.28224 260.5056-66.7648-70.73792 109.24032-157.57312 163.84-260.5056 163.84-102.89152 0-182.23104-54.59968-237.9776-163.84z" fill="#FFE206" p-id="1093"></path><path d="M655.340954 204.8a225.28 225.28 0 0 1 225.28 225.28v40.59136A218.80832 218.80832 0 0 1 743.732634 860.16h-176.7424A218.80832 218.80832 0 0 1 430.060954 470.6304v-40.5504A225.28 225.28 0 0 1 655.340954 204.8z" fill="#51E6F9" p-id="1094"></path><path d="M763.884954 352.6656a277.87264 277.87264 0 0 1-133.85728 521.37984H366.572954A277.87264 277.87264 0 0 1 208.180634 367.90272 281.76384 281.76384 0 0 0 763.884954 352.6656zM482.694554 31.25248a213.85216 213.85216 0 0 1 213.89312 213.89312v63.77472a213.85216 213.85216 0 0 1-427.74528 0V245.1456A213.85216 213.85216 0 0 1 482.653594 31.25248z" fill="#186FE8" opacity=".803" p-id="1095"></path></svg></i>
                <div class="cpcard_t0">数字司法</div>
             </div>
             <div class="cpcard_content">
                 <div class="cp_contitle">
                   打通全业务流程，办案监督一体化
                 </div>
                 <div class="cp_ctext">
                   以数字赋能为支撑，全执法类型网上办理，构建起“一站式、链条式、闭环式”执法监管格局，
                   推动涉企执法工作由“治”向“智”转变。
                 </div>
                   <div class="cp_btns  dic_flex">
                      <a href="#" class="btns1 bt01" @click="getDialog">
                          <span class="btninners1 b01">预约演示</span>
                      </a>
                      <a href="#" class="btns1 bt02" @click="getDialog">
                          <span class="btninners1 b02">查看详情</span>
                      </a>
                 
                 </div>
             </div>
        </div>
        <div class="cpcard">
             <div class="cpcard_title">
                <i><svg t="1709688755725" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1244" width="30" height="30"><path d="M258.671304 845.913043c87.262609 48.350609 173.501217 72.570435 258.671305 72.570435 85.214609 0 179.600696-24.219826 283.158261-72.570435-76.889043 118.739478-171.27513 178.086957-283.158261 178.086957-111.838609 0-198.077217-59.347478-258.671305-178.086957z" fill="#FFE206" p-id="1245"></path><path d="M615.735652 133.565217C815.326609 133.565217 934.956522 267.575652 934.956522 333.779478c0 3.205565-0.311652 6.544696-0.890435 10.061913l0.890435-0.890434-13.000348 315.92626a88.865391 88.865391 0 0 1-36.685913 68.296348C817.152 776.637217 729.889391 801.391304 623.304348 801.391304c-105.605565 0-195.717565-23.774609-270.336-71.368347A88.598261 88.598261 0 0 1 311.652174 655.181913V356.173913l5.164522 5.431652A86.950957 86.950957 0 0 1 311.652174 333.913043C311.652174 267.753739 423.268174 133.565217 615.735652 133.565217z" fill="#51E6F9" p-id="1246"></path><path d="M869.242435 375.496348l-12.288 282.713043a133.698783 133.698783 0 0 1-60.282435 105.961739c-87.440696 57.210435-185.744696 85.793391-294.867478 85.793392-109.434435 0-206.937043-28.716522-292.507826-86.194087a133.654261 133.654261 0 0 1-58.857739-103.245913l-16.072348-285.028174c118.427826 88.153043 236.900174 132.185043 355.372521 132.185043 118.427826 0 244.958609-44.076522 379.503305-132.185043zM489.73913 0c237.30087 0 379.547826 148.925217 379.503305 222.47513 0 71.101217-144.027826 215.79687-379.503305 215.79687C260.274087 438.272 120.653913 296.247652 120.653913 222.608696 120.609391 149.058783 261.030957 0 489.73913 0z" fill="#186FE8" opacity=".803" p-id="1247"></path></svg></i>
                <div class="cpcard_t0">数字公安</div>
             </div>
             <div class="cpcard_content">
                 <div class="cp_contitle">
                    “一站式”服务，打得更精、防得更准
                 </div>
                 <div class="cp_ctext">
                   以“数据”为核心，打造深度中心、城市盾牌等综合支撑平台，整合公安网、感知网、政务网、互联网的数据资源，精准滴灌至应用末端，高效响应全警需求
                 </div>
             </div>
               <div class="cp_btns  dic_flex">
                   <a href="#" class="btns1 bt01" @click="getDialog">
                      <span class="btninners1 b01">预约演示</span>
                   </a>
                    <a href="#" class="btns1 bt02" @click="getDialog">
                      <span class="btninners1 b02">查看详情</span>
                    </a>
                 
                 </div>
        </div>
        <div class="cpcard">
             <div class="cpcard_title">
                <i><svg t="1709688817855" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1396" width="30" height="30"><path d="M195.072 853.333333c83.626667 46.336 166.272 69.546667 247.893333 69.546667 81.664 0 172.117333-23.210667 271.36-69.546667-73.685333 113.792-164.138667 170.666667-271.36 170.666667-107.178667 0-189.824-56.874667-247.893333-170.666667z" fill="#FFE206" p-id="1397"></path><path d="M618.666667 170.666667A64 64 0 0 1 682.666667 234.666667l0.042666 23.338666A290.176 290.176 0 0 1 938.666667 546.133333v17.066667a290.133333 290.133333 0 0 1-290.133334 290.133333h-59.733333A290.133333 290.133333 0 0 1 298.666667 563.2v-17.066667a290.176 290.176 0 0 1 256-288.128V234.666667A64 64 0 0 1 618.666667 170.666667z" fill="#51E6F9" p-id="1398"></path><path d="M582.741333 145.493333A351.701333 351.701333 0 0 1 853.333333 487.765333v22.186667a351.573333 351.573333 0 0 1-351.573333 351.573333H436.906667A351.573333 351.573333 0 0 1 85.333333 509.909333v-22.186666a351.744 351.744 0 0 1 251.306667-337.066667v64.128a123.050667 123.050667 0 1 0 246.058667 0V145.493333zM459.52 35.413333c32 0 57.941333 25.941333 57.941333 57.941334v111.786666a57.941333 57.941333 0 1 1-115.882666 0v-111.786666c0-32 25.941333-57.941333 57.941333-57.941334z" fill="#186FE8" opacity=".803" p-id="1399"></path></svg></i>
                <div class="cpcard_t0">数字电网 </div>
             </div>
             <div class="cpcard_content">
                 <div class="cp_contitle">
                   打通全业务流程，办案监督一体化
                 </div>
                 <div class="cp_ctext">
                   推动电网企业生产经营管理效率与质量提升，并成为能源互联网、工业互联网的关键纽带
                 </div>
             </div>
               <div class="cp_btns  dic_flex">
                   <a href="#" class="btns1 bt01" @click="getDialog">
                      <span class="btninners1 b01">预约演示</span>
                   </a>
                    <a href="#" class="btns1 bt02" @click="getDialog">
                      <span class="btninners1 b02">查看详情</span>
                    </a>
                 
                 </div>
        </div>
        </div>
         
     </div>
  </div>
</template>

<script>
  import api from '@/config/api'
  import {
    getRequst,postRequest
  } from '@/api/index'
export default {

  data() {
    return {
       dataInfo:{},
     }
  },
  created() {

  },
  mounted() {
    //this.getInfo()
  },
  methods: {
     getDialog(){
      this.$emit('openChildFootDialog')
    },
    //获取列表
    getInfo() {
      let data = {
        asId: 1,
      }
      postRequest(api.aboutUsId, data).then(res => {
         this.dataInfo = res.data
      })

    },
  },

}
</script>

<style lang="less" scoped>
  .xa_cpb{
    width:100%;
    background: #F4F6FC;
    padding:30px 0 50px;
    // background: #ffffff;
  }
  .cpcard{
    width:29%;
    margin-right:25px;
    background: #ffffff;
    padding:0 20px 30px;
    border-radius: 4px;
    box-shadow: 0 12px 48px 0 rgba(0,33,71,.1), inset 0 1px 0 0 #fff;
    border:1px rgba(43,114,173,.2) solid;
    padding-bottom:0;
  }
  .cpboxs{
    margin-top:0;
    justify-content: space-around;
  }
  .cpboxs .cpcard:last-of-type{
    margin-right:0;
  }
  .cpcard_title{
    padding:30px 0 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
   border-bottom: 1px solid rgba(0, 11, 23, 0.06);
  }
  .cpcard_title i{
  //   position:absolute;
  //  left:0;
   top:43px;
  }
  .cpcard_title .cpcard_t0{
    font-size: 22px;
    color: #17181b;
    font-weight: 500;
    line-height: 18px;
    /* border-bottom: 1px solid rgba(0, 11, 23, 0.06); */
    /* padding-bottom: 19px; */
    padding-left: 10px;
  }
  .cp_contitle{
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;
    margin-bottom:15px;
    text-align: center;
  }
  .cp_ctext{
    font-size:14px;
    color:#808082;
    line-height:24px;
    background: #ffffff;
  }
  .cpcard-des{
    border:1px solid rgba(0,113,239,.7);
    color: #0071ef;
    font-size: 12px;
    display: inline-block;
    padding-left: 3.5px;
    padding-right: 3.5px;
    border-radius: 3px;
    height:20px;
    text-align: center;
    line-height: 18px;
    vertical-align: middle;
    margin-left: 5px;
  }
  .btns1{
        height: 36px;
    width: 90px;
    cursor: pointer;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 36px;
    color: #fff;
    background-color: #0071ef;
    margin-right: 24px;
    margin-top: 30px;
  
}
.cp_btns{
    opacity: 0;
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
    // -webkit-transition: all .3s ease-in-out;
    // transition: all .3s ease-in-out;
    height:62px;
    justify-content: center;
}
  .bt02{
    background: none;
    border:1px #0071ef solid;
    color:#0071ef;

  }
  .cpcard{
    overflow: hidden;
     -webkit-transform: translateY(12px);
    transform: translateY(12px);
    height: 400px;
    overflow: hidden;
    box-shadow: 0 12px 48px 0 rgba(0,33,71,.05), inset 0 1px 0 0 #fff;
    cursor: pointer;
      -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
  .cpboxs2 .cpcard:hover{
      -webkit-transform: translateY(8px);
    transform: translateY(8px);
  } 
  .cpcard:hover{
    height: 416px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    transform: translateY(-4px);

  }
   .cpboxs2 .cpcard:hover{
      -webkit-transform: translateY(8px);
    transform: translateY(8px);
         -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  } 
  .cpcard:hover .cp_btns{
     opacity: 1;
    -webkit-transform: translateY(-80px);
    transform: translateY(-80px);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  .cpcard:hover .cp_ctext{
     opacity: 0;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.cpcard_title{
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
 .cpcard:hover .cpcard_title{
  -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
 .cpcard:hover .cp_ctext {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.cpboxs{
  height:416px;
}
.cpcard_content{
  padding-top:30px;
}
</style>
