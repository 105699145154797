<template>
  <div class="xa_cpb">
     <div class="dic_title dic_c_white">满足行业需求的整体解决方案</div>
     <div class="dic_sub_title dic_white_7">为客户提供全方位一站式服务，助力数字化升级</div>
     <div class="dic_inner">
       <div class="cp_card_tab  dic_flex">
          <ul class="cp_card_tabhead">
            <li :class="['cp_tabitems', {'active':active==1}]" @click="changeMenu(1)"><span>政务</span></li>
            <li  :class="['cp_tabitems', {'active':active==2}]" @click="changeMenu(2)"><span>交通</span></li>
            <li  :class="['cp_tabitems', {'active':active==3}]" @click="changeMenu(3)"><span>教育</span></li>
            <li  :class="['cp_tabitems', {'active':active==4}]"  @click="changeMenu(4)"><span>水电</span></li>
            <li  :class="['cp_tabitems', {'active':active==5}]"  @click="changeMenu(5)"><span>制造</span></li>
            <li  :class="['cp_tabitems', {'active':active==6}]"  @click="changeMenu(6)"><span>地产</span></li>
            <li  :class="['cp_tabitems', {'active':active==7}]"  @click="changeMenu(7)"><span>医疗</span></li>
          </ul>
          <div class="cp_card_tabcontent">
              <div class="cp_tabiconts">
                <div class="cp_boxs dic_flex dic_ja">
                  <!-- <div class="cp_left">
                    <img src="../assets/images/index/indexcp01.jpg" alt="">
                  </div> -->
                  <div  :class="['cp_right', {'active':active==1}]">
                      <div class="cp_boxtents">行政执法解决方案</div>
                      <div class="cp_boxtents_inner">
                        以“统一平台、统一门户、统一标准”的执法监督云平台为载体,对内执法信息全过程记录、执法业务流程规范化定制、
                        重大行政执法决定法制审核可监督、宏观决策数据可提取，对外实现社会公众可参与的行政执法公示.
                        <!-- 从执法到结案,执法类型全流程网上办理，从根本上促进执法的法制化、精细化、智能化，实现执法效果与
                        社会效果相统一，执法管理与监督，执法审批，执法辅助功能，协同办公 -->
                      </div>
                      <div class="cp_btns">
                          <div class="cp_dl-box dic_flex">
                            <div class="cp_dl">
                              <div class="cp_dt iconfont">&#xe650;</div>
                              <div class="cp_dd">执法记录全程化</div>
                            </div>
                             <div class="cp_dl">
                              <div class="cp_dt iconfont">&#xe640;</div>
                              <div class="cp_dd">执法公示常态化</div>
                            </div>
                              <div class="cp_dl">
                              <div class="cp_dt iconfont">&#xe663;</div>
                              <div class="cp_dd">执法监督实时化</div>
                            </div>
                            <div class="cp_dl">
                              <div class="cp_dt iconfont">&#xe637;</div>
                              <div class="cp_dd">执法考核智能化</div>
                            </div>
                          </div>
                      </div>
                      <div class="cp_op_btns dic_flex">
                        <div class="cp_l_bts">免费试用</div>
                         <div class="cp_r_bts">了解详情</div>
                      </div>
                  </div>
                  <div  :class="['cp_right', {'active':active==2}]">
                    <div class="cp_boxtents">交通执法解决方案</div>
                  </div>
                  <div  :class="['cp_right', {'active':active==3}]">
                    <div class="cp_boxtents"></div>
                  </div>
                   <div  :class="['cp_right', {'active':active==4}]">
                    <div class="cp_boxtents"></div>
                  </div>
                  <div  :class="['cp_right', {'active':active==5}]">
                    <div class="cp_boxtents"></div>
                  </div>
                  <div  :class="['cp_right', {'active':active==6}]">
                    <div class="cp_boxtents"></div>
                  </div>
                  <div  :class="['cp_right', {'active':active==6}]">
                    <div class="cp_boxtents"></div>
                  </div>
                </div>
                  
              </div>
          </div>
       </div>
     </div>
  </div>
</template>

<script>
  import api from '@/config/api'
  import {
    getRequst,postRequest
  } from '@/api/index'
export default {

  data() {
    return {
       dataInfo:{},
       active:1
     }
  },
  created() {

  },
  mounted() {
    //this.getInfo()
  },
  methods: {
    changeMenu(item){
      this.active = item
    },
    //获取列表
    getInfo() {
      let data = {
        asId: 1,
      }
      postRequest(api.aboutUsId, data).then(res => {
         this.dataInfo = res.data
      })

    },
  },

}
</script>

<style lang="less" scoped>
  .xa_cpb{
    width:100%;
    background: #ffffff;
    padding:30px 0 80px;
  }
  .cp_card_tabhead{
   border-right: 1px rgba(255,255,255,.1) solid;
  }
 .cp_card_tabhead li {
    width:160px;
    height: 78px;
    margin-right: 24px;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: rgba(255,255,255,.7);
    cursor: pointer;
  }
  .cp_card_tabhead li.active {
    color: #ffffff;
}
.cp_card_tabhead li.active span {
    padding-bottom: 6px;
    // border-bottom: 3px solid #ffffff;
    position: relative;
}
.cp_card_tabhead li.active span:after{
  content:'';
  position: absolute;
  width:3px;
  height:30px;
  background: #0071ef;
  left:-30px;
  top:50%;
  margin-top:-15px;
}
.cp_card_tabhead li.active span:before{
  content:'';
  position: absolute;
  width:10px;
  height:2px;
  background: #ffffff;
  left:50%;
  bottom:-2px;
  margin-left:-5px;
}
.cp_boxs{
  margin-top:40px;
}
.cp_boxtents{
  color:#ffffff;
  font-size: 22px;
  margin-bottom:30px;
}
.xa_cpb{
   background: url(../assets/images/index/indexbg.png) no-repeat center center;
   background-size:cover;
}
.cp_right{
  padding-left:80px;
  display: none;
}
.cp_right.active{
  display: block;
}
.cp_card_tab{
  margin-top:60px;
}
.cp_boxtents_inner{
  font-size:16px;
  line-height: 28px;
  color:rgba(235, 235, 245, 0.7);
}
.cp_dl{
  margin-top:40px;
  text-align: center;
  margin-right: 90px;
}
.cp_dt{
  font-size: 34px;
  color:rgba(255,255,255,.9);
}
.cp_dd{
  color:rgba(255,255,255,.9);
  margin-top:20px;
  font-size:14px;
}
.cp_dl-box{
  margin-top:20px;
  padding-bottom:70px;
  border-bottom:1px rgba(255,255,255,.1) dashed;
}
.cp_l_bts{
   position: relative;
    height: 40px;
    width: 104px;
    cursor: pointer;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    color: #fff;
    background-color: #0071ef;
    margin-right:24px;
    margin-top:60px;
}
.cp_r_bts{
    position: relative;
    height: 40px;
    width: 104px;
    border-radius: 2px;
    border-width: 1px;
    text-align: center;
    font-size: 14px;
    line-height: 40px;
    color: #fff;
    border-color: #fff;
    margin-top:60px;
    border: 1px solid #e5e7eb;
}
.cp_l_bts:hover:after,.cp_r_bts:hover:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 40px;
    background-color: hsla(0,0%,100%,.1);
}
</style>
