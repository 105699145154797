<template>
  <div class="xa_cpb">
     <div class="dic_title">我们的优势</div>
     <div class="dic_sub_title ">强大的研发实力，安全稳定可靠的系统支持</div>
     <div class="dic_inner ">
       <div class="dic_flex dic_jsa c_card_inner">
      <div class="c_card">
          <div class="c_card_tc"><i class="ysicon iconfont">&#xe6b1;</i>资源优势</div>
          <div class="c_card_list"><i class="licon iconfont">&#xe67e;</i>80%技术人员构成强有力研发</div>
          <div class="c_card_list"><i class="licon iconfont">&#xe67e;</i>专业懂行的售前及服务团队</div>
          <div class="c_card_list"><i class="licon iconfont">&#xe67e;</i>智慧城市领域深耕20多年</div>
          <div class="c_card_list"><i class="licon iconfont">&#xe67e;</i>深厚的案例积累</div>
      </div>
      <div class="c_card">
         <div class="c_card_tc"><i class="ysicon iconfont">&#xe6d5;</i>服务优势</div>
          <div class="c_card_list"><i class="licon iconfont">&#xe67e;</i>一站式服务，实现降本增效</div>
          <div class="c_card_list"><i class="licon iconfont">&#xe67e;</i>服务流程精准、高效</div>
          <div class="c_card_list"><i class="licon iconfont">&#xe67e;</i>优质、便捷、全方位服务体验</div>
          <div class="c_card_list"><i class="licon iconfont">&#xe67e;</i>快速响应、快速诊断</div>
      </div>
       <div class="c_card">
         <div class="c_card_tc"><i class="ysicon iconfont">&#xe6d1;</i>产品优势</div>
          <div class="c_card_list pl50"><i class="licon iconfont">&#xe67e;</i>系统安全稳定</div>
          <div class="c_card_list pl50"><i class="licon iconfont">&#xe67e;</i>满足个性化定制开发</div>
          <div class="c_card_list pl50"><i class="licon iconfont">&#xe67e;</i>强有力技术支持</div>
            <div class="c_card_list pl50"><i class="licon iconfont">&#xe67e;</i>大客户标杆案例</div>
      </div>
      <div class="c_card">
         <div class="c_card_tc"><i class="ysicon iconfont">&#xe6cf;</i>100+客户的信赖</div>
            <div class="c_card_list"><i class="licon iconfont">&#xe67e;</i>省级省委省情库</div>
            <div class="c_card_list"><i class="licon iconfont">&#xe67e;</i>省级省发改委大数据中心</div>
            <div class="c_card_list"><i class="licon iconfont">&#xe67e;</i>市级行政执法监督云平台</div>
            <div class="c_card_list"><i class="licon iconfont">&#xe67e;</i>市级教育局一键视频报警</div>
            <div class="c_card_f iconfont dic_flex">&#xe683;</div>
      </div>
     </div>
     </div>
      <div class="dic_title">获得多项资质证书，专业能力备受认可</div>
       <div class="dic_inner ">
         <div class="zs_box dic_flex dic_jsa">
           <div class="zs_items">
             <div class="zs_pics">
               <img src="../assets/images/index/zs01.png" alt="">
             </div>
             <div class="zs_test">质量管理体系认证</div>
           </div>
             <div class="zs_items">
              <div class="zs_pics">
                 <img src="../assets/images/index/zs02.png" alt="">
              </div>
              <div class="zs_test">环境管理体系认证</div>
           </div>
            <div class="zs_items">
              <div class="zs_pics">
                <img src="../assets/images/index/zs07.png" alt="">
              </div>
              <div class="zs_test">武汉市科技小巨人企业</div>
           </div>
            <div class="zs_items">
             <div class="zs_pics">
               <img src="../assets/images/index/zs06.png" alt="">
             </div>
             <div class="zs_test">武汉市大数据示范项目</div>
           </div>
             <div class="zs_items">
              <div class="zs_pics">
                <img src="../assets/images/index/zs05.png" alt="">
              </div>
              <div class="zs_test">楚商优秀企业</div>
           </div>
           <div class="zs_items">
              <div class="zs_pics">
                <img src="../assets/images/index/zs05.png" alt="">
              </div>
              <div class="zs_test">楚商联合会理事单位</div>
           </div>
            <div class="zs_items">
              <div class="zs_pics">
                <img src="../assets/images/index/zs03.png" alt="">
              </div>
              <div class="zs_test">3551光谷人才计划</div>
           </div>
         </div>
       </div>
  </div>
</template>

<script>
  import api from '@/config/api'
  import {
    getRequst,postRequest
  } from '@/api/index'
export default {

  data() {
    return {
       dataInfo:{},
     }
  },
  created() {

  },
  mounted() {
   // this.getInfo()
  },
  methods: {
    //获取列表
    getInfo() {
      let data = {
        asId: 1,
      }
      postRequest(api.aboutUsId, data).then(res => {
         this.dataInfo = res.data
      })

    },
  },

}
</script>

<style lang="less" scoped>
  .xa_cpb{
    width:100%;
    background: #F4F6FC;
    padding:30px 0 80px;
  }
  .c_card_inner{
    padding:40px 0;
  }
  .c_card{
    width: 35%;
    height: 360px;
    // margin-right: 40px;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px 0px rgba(0, 117, 255, 0.12);
    }
    .c_card_tc{
      text-align: center;
      font-size: 22px;
      height:70px;
      border-bottom:1px #F2F3F5 solid;
      line-height: 70px;
      margin-bottom:30px;
      background: url(../assets/images/index/ysbg.png) center center;
      color:#ffffff;
    }
    .c_card_list{
      padding:5px 20px;
      line-height: 24px;
      color:#272E3B;
    }
    .zs_box{
      margin-top:40px;
    }
    .zs_pics{
      width:100px;
      height:100px;
      border-radius: 50%;
      background: #ffffff;
    }
    .zs_test{
      font-size: 14px;
      text-align: center;
      line-height: 18px;
      margin:0 auto;
      margin-top:20px;
      width:90px;
    }
    .ysicon{
      font-size: 32px;
      vertical-align: middle;
      display: inline-block;
      margin-right:8px;
      opacity: .9;
    }
    .licon{
      display: inline-block;
      color:#0071ef;
      font-weight: bold;
      margin-right: 30px;
    }
    .c_card_list{
      padding-left:30px;
      margin:20px 0;
    }
    .c_card_f{
      font-size: 32px;
      text-align: center;
      color:#0071ef;
      justify-content: center;
    }
    .zs_pics img{
      width:100px;
      height:100px;
    }
</style>
