<template>
  <div class="xa_cpb">
    <div class="dic_title">核心产品</div>
    <div class="dic_sub_title">深耕三大领域,打造一站式数字化服务</div>
    <div class="dic_inner">
      <div class="cpboxs dic_flex mt40">
        <div class="cpcard">
          <!-- <div class="cpcard_title">
                <div class="cpcard_t0">郧西行政执法监督云平台  
                </div>
             </div> -->
          <div class="cpcard_content">
            <!-- <div class="cp_contitle">
                   打通全业务流程，办案监督一体化
                 </div> -->
            <div class="cp_ctext">
              <img src="../assets/images/s02.png" alt="" />
            </div>
            <div class="itemnamse">行政执法监督云平台</div>
            <div class="cp_btns dic_flex">
              <a href="#" class="btns1 bt01"  @click="getDialog">
                <span class="btninners1 b01">预约演示</span>
              </a>
              <a href="#" class="btns1 bt02"  @click="getDialog">
                <span class="btninners1 b02">查看详情</span>
              </a>
            </div>
          </div>
        </div>
        <div class="cpcard">
          <!-- <div class="cpcard_title">
                <div class="cpcard_t0">郧西行政执法监督云平台  
                </div>
             </div> -->
          <div class="cpcard_content">
            <!-- <div class="cp_contitle">
                   打通全业务流程，办案监督一体化
                 </div> -->
            <div class="cp_ctext">
              <img src="../assets/images/stob.png" alt="" />
            </div>
            <div class="itemnamse">规范性文件平台</div>
            <div class="cp_btns dic_flex">
              <a href="#" class="btns1 bt01"  @click="getDialog">
                <span class="btninners1 b01">预约演示</span>
              </a>
              <a href="#" class="btns1 bt02"  @click="getDialog">
                <span class="btninners1 b02">查看详情</span>
              </a>
            </div>
          </div>
        </div>
        <div class="cpcard">
          <!-- <div class="cpcard_title">
                <div class="cpcard_t0">郧西行政执法监督云平台  
                </div>
             </div> -->
          <div class="cpcard_content">
            <!-- <div class="cp_contitle">
                   打通全业务流程，办案监督一体化
                 </div> -->
            <div class="cp_ctext">
              <img src="../assets/images/news2.png" alt="" />
            </div>
            <div class="itemnamse">找堵防</div>
            <div class="cp_btns dic_flex">
              <a href="#" class="btns1 bt01"  @click="getDialog">
                <span class="btninners1 b01">预约演示</span>
              </a>
              <a href="#" class="btns1 bt02"  @click="getDialog">
                <span class="btninners1 b02">查看详情</span>
              </a>
            </div>
          </div>
        </div>
        <div class="cpcard">
          <!-- <div class="cpcard_title">
                <div class="cpcard_t0">郧西行政执法监督云平台  
                </div>
             </div> -->
          <div class="cpcard_content">
            <!-- <div class="cp_contitle">
                   打通全业务流程，办案监督一体化
                 </div> -->
            <div class="cp_ctext">
              <img src="../assets/images/jitt.png" alt="" />
            </div>
            <div class="itemnamse">交通管控</div>
            <div class="cp_btns dic_flex">
              <a href="#" class="btns1 bt01"  @click="getDialog">
                <span class="btninners1 b01">预约演示</span>
              </a>
              <a href="#" class="btns1 bt02"  @click="getDialog">
                <span class="btninners1 b02">查看详情</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="cpboxs cpboxs2 dic_flex">
        <div class="cpcard">
          <!-- <div class="cpcard_title">
                <div class="cpcard_t0">郧西行政执法监督云平台  
                </div>
             </div> -->
          <div class="cpcard_content">
            <!-- <div class="cp_contitle">
                   打通全业务流程，办案监督一体化
                 </div> -->
            <div class="cp_ctext">
              <img src="../assets/images/info5.jpg" alt="" />
            </div>
            <div class="itemnamse">大数据资产认证平台</div>
            <div class="cp_btns dic_flex">
              <a href="#" class="btns1 bt01"  @click="getDialog">
                <span class="btninners1 b01">预约演示</span>
              </a>
              <a href="#" class="btns1 bt02"  @click="getDialog">
                <span class="btninners1 b02">查看详情</span>
              </a>
            </div>
          </div>
        </div>
           <div class="cpcard">
          <!-- <div class="cpcard_title">
                <div class="cpcard_t0">郧西行政执法监督云平台  
                </div>
             </div> -->
          <div class="cpcard_content">
            <!-- <div class="cp_contitle">
                   打通全业务流程，办案监督一体化
                 </div> -->
            <div class="cp_ctext">
              <img src="../assets/images/info2.jpg" alt="" />
            </div>
            <div class="itemnamse">电力营销系统</div>
            <div class="cp_btns dic_flex">
              <a href="#" class="btns1 bt01"  @click="getDialog">
                <span class="btninners1 b01">预约演示</span>
              </a>
              <a href="#" class="btns1 bt02"  @click="getDialog">
                <span class="btninners1 b02">查看详情</span>
              </a>
            </div>
          </div>
        </div>
        <div class="cpcard">
          <!-- <div class="cpcard_title">
                <div class="cpcard_t0">郧西行政执法监督云平台  
                </div>
             </div> -->
          <div class="cpcard_content">
            <!-- <div class="cp_contitle">
                   打通全业务流程，办案监督一体化
                 </div> -->
            <div class="cp_ctext">
              <img src="../assets/images/info3.jpg" alt="" />
            </div>
            <div class="itemnamse">电能计量系统</div>
            <div class="cp_btns dic_flex">
              <a href="#" class="btns1 bt01"  @click="getDialog">
                <span class="btninners1 b01">预约演示</span>
              </a>
              <a href="#" class="btns1 bt02"  @click="getDialog">
                <span class="btninners1 b02">查看详情</span>
              </a>
            </div>
          </div>
        </div>
        <div class="cpcard">
          <!-- <div class="cpcard_title">
                <div class="cpcard_t0">郧西行政执法监督云平台  
                </div>
             </div> -->
          <div class="cpcard_content">
            <!-- <div class="cp_contitle">
                   打通全业务流程，办案监督一体化
                 </div> -->
            <div class="cp_ctext">
              <img src="../assets/images/st1.png" alt="" />
            </div>
            <div class="itemnamse">电力三防</div>
            <div class="cp_btns dic_flex">
              <a href="#" class="btns1 bt01"  @click="getDialog">
                <span class="btninners1 b01">预约演示</span>
              </a>
              <a href="#" class="btns1 bt02"  @click="getDialog">
                <span class="btninners1 b02">查看详情</span>
              </a>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/config/api";
import { getRequst, postRequest } from "@/api/index";
export default {
  data() {
    return {
      dataInfo: {},
    };
  },
  created() {},
  mounted() {
    //this.getInfo()
  },
  methods: {
     getDialog(){
      this.$emit('openChildFootDialog')
    },
    //获取列表
    getInfo() {
      let data = {
        asId: 1,
      };
      postRequest(api.aboutUsId, data).then((res) => {
        this.dataInfo = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.xa_cpb {
  width: 100%;
  background: #ffffff;
  padding: 30px 0 80px;
}
.cpcard {
  width: 25%;
  margin-right: 25px;
  background: #ffffff;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 12px 48px 0 rgba(0, 33, 71, 0.1), inset 0 1px 0 0 #fff;
  padding-bottom: 0;
}
.cpcard img {
  width: 100%;
  height: 100%;
}
.cpboxs {
  margin-top: 0;
}
.cpboxs .cpcard:last-of-type {
  margin-right: 0;
}
.cpcard_title {
  padding: 0;
  position: relative;
}
.cpcard_title i {
  position: absolute;
  left: 0;
  top: 43px;
}
.cpcard_title .cpcard_t0 {
  font-size: 18px;
  color: #17181b;
  font-weight: 500;
  line-height: 18px;
  border-bottom: 1px solid rgba(0, 11, 23, 0.06);
  padding-bottom: 19px;
  padding-left: 40px;
}
.cp_contitle {
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 15px;
}
.cp_ctext {
  font-size: 14px;
  color: #808082;
  line-height: 24px;
  background: #ffffff;
  height:230px;
}
.cpcard-des {
  border: 1px solid rgba(0, 113, 239, 0.7);
  color: #0071ef;
  font-size: 12px;
  display: inline-block;
  padding-left: 3.5px;
  padding-right: 3.5px;
  border-radius: 3px;
  height: 20px;
  text-align: center;
  line-height: 18px;
  vertical-align: middle;
  margin-left: 5px;
}
.btns1 {
  height: 36px;
  width: 90px;
  cursor: pointer;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  color: #fff;
  background-color: #0071ef;
  margin-right: 24px;
  margin-top: 30px;
}
.cp_btns {
  opacity: 0;
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
  // -webkit-transition: all .3s ease-in-out;
  // transition: all .3s ease-in-out;
  height: 62px;
  display: flex;
  justify-content: center;
}
.bt02 {
  background: none;
  border: 1px #ffffff solid;
  color: #ffffff;
}
.cpcard {
  overflow: hidden;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  height: 280px;
  overflow: hidden;
  box-shadow: 0 12px 48px 0 rgba(0, 33, 71, 0.05), inset 0 1px 0 0 #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpboxs2 .cpcard:hover {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}
.cpcard:hover {
  height: 296px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transform: translateY(-4px);
}
.cpboxs2 .cpcard:hover {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_btns {
  opacity: 1;
  -webkit-transform: translateY(-140px);
  transform: translateY(-140px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_ctext {
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard_title {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cpcard_title {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_ctext {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpboxs {
  height: 306px;
}
.itemnamse {
  height: 40px;
  line-height: 40px;
  text-align: center;
}
</style>
